<template>
  <div class="col main_topics_wrapper_container">
    <!-- Start Server Table -->

    <server-table
      class=""
      :count="usersCategoriesDetailsCount"
      :DataItems="usersCategoriesDetails"
      :loading="loadingValue"
      @Refresh="getUserCategoriesData"
      @reset="filter = {}"
      :filter="filter"
      :title="`${localization('Topics')}`"
      :action="false"
      edit_permission="add_topics"
    >
      <template #columns>
        <el-table-column
          sortable
          prop="first_name"
          min-width="100px"
          :label="`${localization('First Name')}`"
        >
        </el-table-column>
        <el-table-column
          prop="last_name"
          sortable
          :label="`${localization('Last Name')}`"
          min-width="100px"
        >
        </el-table-column>

        <el-table-column
          prop="email"
          :label="`${localization('Email')}`"
          min-width="100px"
        >
        </el-table-column>

        <el-table-column prop="" label="_" width="140">
          <template slot-scope="scope">
            <div class="td-actions">
              <a
                @click="removeOneUserTask(scope.row.id)"
                v-tooltip.top-center="`Delete user`"
                :class="`btn btn-danger btn-link btn-xs`"
              >
                <i class="fa fa-times"></i>
              </a>
            </div>
          </template>
        </el-table-column>
      </template>

      <template #action>
        <div type="info" class="ml-2" @click="open_topic_dialog(1, '', null)">
          <el-icon class="el-icon-plus"></el-icon>
          {{ localization("Add Task") }}
        </div>
      </template>
    </server-table>
    <!-- End Server Table  -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import "element-ui/lib/theme-chalk/display.css";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filter: {},
      taskData: {
        name: "",
        id: null
      },
      type: null,
      topic_dialog: false,
      rules: {
        name: [
          {
            required: true,
            message: "Please this field is required",
            trigger: "blur"
          }
        ]
      }
    };
  },
  components: { ServerTable },
  mounted() {
    this.getUserCategoriesData();
  },
  methods: {
    // this.$route.params.id
    getUserCategoriesData() {
      this.$store.dispatch(
        "topics/handlerUserCategoriesDetails",
        this.$route.params.id
      );
    },
    // payload.query, payload.id
    open_topic_dialog(type, value, id) {
      this.taskData.name = value;
      this.taskData.id = id;
      this.topic_dialog = true;
      this.type = type;
    },
    save() {
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          if (this.type == 1) {
            this.$store
              .dispatch("topics/addUserTask", { name: this.taskData.name })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.getUserCategoriesData();
                this.topic_dialog = false;
              });
          } else {
            this.$store
              .dispatch("topics/addUserTask", {
                id: this.taskData.id,
                name: this.taskData.name
              })
              .then(_ => {
                Swal.fire({
                  title: "",
                  icon: "success",
                  text: "Done",
                  type: "success",
                  confirmButtonClass: "btn btn-success ",
                  buttonsStyling: false
                });
                this.getUserCategoriesData();
                this.topic_dialog = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    removeOneUserTask(userId) {
      Swal.fire({
        text: `${this.localization("Are You Sure")}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success",
        cancelButtonClass: "btn btn-danger",
        confirmButtonText: `${this.localization("Delete")}`,
        cancelButtonText: `${this.localization("Cancel")}`,
        buttonsStyling: false,
        icon: "error"
      }).then(date => {
        if (date.isConfirmed) {
          this.$store
            .dispatch("topics/deleteOneUserTask", {
              id: this.$route.params.id,
              user_id: userId
            })
            .then(_ => {
              this.getUserCategoriesData();
              Swal.fire({
                title: "",
                text: `${this.localization("Deleted")}`,
                confirmButtonText: `${this.localization("Ok")}`,
                type: "success",
                confirmButtonClass: "btn btn-success",
                buttonsStyling: false,
                icon: "success"
              });
            });
        }
      });
    }
  },
  computed: {
    ...mapGetters({
      loadingValue: ["topics/usersLoad"],
      usersCategoriesDetails: ["topics/getUsersCategoriesDetails"],
      usersCategoriesDetailsCount: ["topics/getUsersCategoriesDetailsCount"]
    })
  }
};
</script>

<style lang=""></style>
